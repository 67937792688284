import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/seo"

function Index() {
  return (
    <>
      <SEO title="Log In | Omniplex Learning" />
      <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            class="mx-auto h-16 w-auto"
            src="https://omniplexlearning.com/wp-content/themes/Omniplex/image/omni-logo-dark.svg"
            alt="Omniplex"
          />
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" action="#" method="POST">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    value="example@omniplexlearning.com"
                    disabled
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    disabled
                    value="nickelled"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <Link
                  to="/omniplex-sandbox/person/"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-amber-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Sign in
                </Link>
              </div>
            </form>
            <div className="pt-4">
              <div class="text-sm text-center">
                <div class="text-sm">
                  <a
                    href="/omniplex-sandbox/signup/"
                    class="font-medium text-gray-900 hover:text-gray-900"
                  >
                    Create an account
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full text-center mt-8 text-sm">
            <div className="inline-block w-full my-1 ">
              <a
                href="/omniplex-sandbox/person/"
                class="font-medium text-gray-900 hover:text-gray-900"
              >
                View Person Page
              </a>
            </div>
            <div className="inline-block w-full my-1">
              <a
                href="/omniplex-sandbox/settings/"
                class="font-medium text-gray-900 hover:text-gray-900"
              >
                View Settings Page
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer class="bg-white">
        <div class="max-w-7xl mx-auto py-8 px-4 overflow-hidden sm:px-6 lg:px-8">
          <p class="mt-8 text-center text-base text-gray-400">
            This UI mockup is by the fantastic team at{" "}
            <a
              href="https://tailwindui.com/"
              class=" text-gray-900"
              target="_blank"
            >
              TailwindUI
            </a>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Index
